import axios from "axios";
import { API_URL } from "../.env";

export class AppService {
  static async getListPermission(token, appId) {
    try {
      const response = await axios({
        method: "get",
        url: `${API_URL}/applications/${appId}/scopes`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async allowPermission(token, merchantId, appId) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/merchants/${merchantId}/applications/${appId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async denyPermission(token, merchantId, appId) {
    try {
      const response = await axios({
        method: "delete",
        url: `${API_URL}/merchants/${merchantId}/applications/${appId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
}
