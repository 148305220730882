import axios from "axios";
import { API_URL, NEXT360_URL_HOST } from "../.env";

export class MerchantService {
  static async getList(token) {
    try {
      const response = await axios({
        method: "get",
        url: `${API_URL}/merchant`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async createMerchant(params, token) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/merchant`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: params,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async getListBusinessField(token) {
    try {
      const response = await axios({
        method: "post",
        url: `${NEXT360_URL_HOST}/api/business-field-list`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
}
