<template>
  <div class="container-main-content select-enterprise">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-2">Chọn doanh nghiệp</h4>
        <p class="f14 text-second-color mb-4">để hoàn tất đăng nhập Next360</p>
        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="handleCreateEnterprise"
        >
          TẠO DOANH NGHIỆP MỚI
        </button>
        <div class="content-inner__body-list mb-4">
          <div
            v-for="(item, index) in dataMerchant"
            :key="index"
            @click="showModalPermission(item)"
            class="
              content-inner__body-item
              d-flex
              justify-content-between
              align-items-center
              mb-3
            "
          >
            <div>
              <h6 class="f14 fw600">{{ item.name }}</h6>
              <p class="f14 text-second-color">
                {{ APP_DOMAIN }}/s/{{ item.aliasMerchant }}
              </p>
            </div>
            <img src="../../assets/images/arrow-right-black.svg" />
          </div>
        </div>
        <p class="f14 text-second-color mb-4">
          <a href="#" class="link-primary-color" @click.prevent="handleLogout">
            Đăng nhập tài khoản khác
          </a>
        </p>
      </div>
      <Footer />
    </div>

    <!-- Modal request permission -->
    <b-modal
      v-model="modalPermission"
      centered
      size="md"
      title="Ứng dụng cần cấp các quyền"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header="{ close }">
        <h5>Ứng dụng cần cấp các quyền</h5>
        <b-button size="md" variant="light" @click="close()"> x </b-button>
      </template>
      <div>
        <ul style="margin-bottom: 0">
          <li v-for="(item, index) in listPermission" :key="index">
            <b>{{ item.name }}: </b> {{ item.description }}
          </li>
        </ul>
      </div>
      <template #modal-footer="{}">
        <b-button variant="primary" @click="allowPermission">Đồng ý</b-button>
        <b-button variant="danger" @click="denyPermission">Từ chối</b-button>
      </template>
    </b-modal>
    <!-- End modal request permission -->

    <b-modal
      v-model="modalNoti.show"
      title="Thông báo"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
      centered
    >
      <!-- <template #modal-header="{ close }">
        <h5>Thông báo</h5>
        <b-button size="sm" variant="light" @click="close()"> x </b-button>
      </template> -->
      <div class="text-center">
        <img
          v-if="modalNoti.status === 'success'"
          src="@/assets/images/noti-success.svg"
        />
        <img
          v-else-if="modalNoti.status === 'fail'"
          src="@/assets/images/noti-fail.svg"
        />
        <img v-else src="@/assets/images/noti-warning.svg" />
        <p class="f18 pt-4">{{ modalNoti.content }}</p>
      </div>
      <!-- <template #modal-footer="{ close }">
        <b-button size="sm" variant="light" @click="close()"> Đóng </b-button>
      </template> -->
    </b-modal>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {
  APP_DOMAIN,
  // NEXTSHOP_APPID
} from "../../.env";
import { MerchantService } from "../../services/merchant.service";
import { AuthService } from "../../services/auth.service";
import { AppService } from "../../services/app.service";
import { byPassAppIds } from "../../config";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      APP_DOMAIN,
      dataMerchant: [],
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      token: this.$cookies.get("token"),
      userType: this.$cookies.get("userType"),
      appId: this.$route.query.appId ? this.$route.query.appId : "",
      redirectUri: this.$route.query.redirectUri
        ? this.$route.query.redirectUri
        : "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
      modalPermission: false,
      listPermission: [],
      merchantId: "",
      modalNoti: {
        show: false,
        status: null,
        content: null,
        showHeader: false,
        showFooter: false,
      },
      byPassAppIds,
    };
  },
  created() {
    this.getListMerchant();
    // this.getListAppScope();
  },
  methods: {
    async getListMerchant() {
      try {
        this.loading.isLoading = true;
        const response = await MerchantService.getList(this.token);
        response.data.map((item) => {
          if (item.status == "active") {
            this.dataMerchant.push(item);
          }
        });
        if (this.dataMerchant.length === 0) {
          this.$router.push({
            path: `/create-enterprise${this.currentQueries}`,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
    async authorize() {
      try {
        this.loading.isLoading = true;
        const response = await AuthService.authorize(
          this.token,
          this.merchantId,
          this.appId,
          decodeURIComponent(this.redirectUri)
        );
        if (response.code === "SUCCESS") {
          console.log("Success");
          if (response.data.authorizedCallbackUri.includes("?")) {
            window.location.href = `${response.data.authorizedCallbackUri}&authorizationCode=${response.data.authorizationCode}&userId=${response.data.userId}&userType=${response.data.userType}&merchantId=${response.data.merchantId}`;
          } else {
            window.location.href = `${response.data.authorizedCallbackUri}?authorizationCode=${response.data.authorizationCode}&userId=${response.data.userId}&userType=${response.data.userType}&merchantId=${response.data.merchantId}`;
          }
        } else if (response.data.code === "APPLICATION_NOT_FOUND") {
          alert("APPLICATION_NOT_FOUND");
        } else {
          alert(response.data.code);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
    showModalPermission(merchant) {
      const { appId } = this.$route.query;
      this.merchantId = merchant.merchantId;
      if (this.userType === "USER" && this.byPassAppIds.includes(appId)) {
        this.authorize();
      } else if (this.userType === "ALIAS_USER") {
        this.authorize();
      } else {
        this.getListAppScope();
      }
    },
    async getListAppScope() {
      try {
        this.loading.isLoading = true;
        const { appId } = this.$route.query;
        const response = await AppService.getListPermission(this.token, appId);
        if (response.code === "SUCCESS") {
          this.listPermission = response.data.scopes;
          this.modalPermission = true;
        } else {
          this.modalPermission = false;
          alert("Có lỗi xảy ra");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
    async allowPermission() {
      try {
        const merchantId = this.merchantId;
        const { appId } = this.$route.query;
        const response = await AppService.allowPermission(
          this.token,
          merchantId,
          appId
        );
        if (response.code === "SUCCESS") {
          this.modalPermission = false;
          this.showModalNoti("success", "Cấp quyền thành công");
          setTimeout(() => {
            this.authorize();
          }, 3000);
        } else {
          this.modalPermission = false;
          this.showModalNoti("fail", "Có lỗi xảy ra");
          setTimeout(() => {
            this.hideModalNoti();
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async denyPermission() {
      try {
        const merchantId = this.merchantId;
        const { appId } = this.$route.query;
        await AppService.denyPermission(this.token, merchantId, appId);
        this.modalPermission = false;
        this.showModalNoti("warning", "Đã từ chối cấp quyền");
        setTimeout(() => {
          this.hideModalNoti();
        }, 3000);
      } catch (error) {
        console.log(error);
      }
    },
    handleCreateEnterprise() {
      this.$router.push({ path: `/create-enterprise${this.currentQueries}` });
    },
    handleLogout() {
      this.$router.push(`/logout${this.currentQueries}`);
    },
    showModalNoti(status, content) {
      this.modalNoti.show = true;
      this.modalNoti.status = status;
      this.modalNoti.content = content;
    },
    hideModalNoti() {
      this.modalNoti.show = false;
    },
  },
};
</script>
